import React, {useState} from 'react';
import s from "../../EditProperty.module.scss";
import {Checkbox, Input} from "antd";
import {BrokeragePropertyPicturesTabData} from '../../../../../store/propertiesReducer';

type ShowMorePropsType = {
    onFormInputChange: (id: number, e: React.ChangeEvent<HTMLInputElement> | boolean, inputName: string) => void
    i: BrokeragePropertyPicturesTabData
}

const ShowMore = ({onFormInputChange, i}: ShowMorePropsType) => {
    const [isAdditionalParamsShown, setIsAdditionalParamsShown] = useState<boolean>(false)

    return (
        <>
            {/*<div className={s.showMore}*/}
            {/*     onClick={() => setIsAdditionalParamsShown(!isAdditionalParamsShown)}>*/}
            {/*    <p>Show more</p>*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="20"*/}
            {/*         height="20" viewBox="0 0 20 20" fill="none" style={{*/}
            {/*        transform: !isAdditionalParamsShown ? 'rotate(0deg)' : 'rotate(180deg)'*/}
            {/*    }}>*/}
            {/*        <path fillRule="evenodd" clipRule="evenodd"*/}
            {/*              d="M10.5893 13.0889C10.2638 13.4144 9.73618 13.4144 9.41074 13.0889L5.24408 8.92226C4.91864 8.59683 4.91864 8.06919 5.24408 7.74375C5.56951 7.41832 6.09715 7.41832 6.42259 7.74375L10 11.3212L13.5774 7.74375C13.9028 7.41832 14.4305 7.41832 14.7559 7.74375C15.0814 8.06919 15.0814 8.59683 14.7559 8.92226L10.5893 13.0889Z"*/}
            {/*              fill="#1755E7"/>*/}
            {/*    </svg>*/}
            {/*</div>*/}

            <div className={s.additionalParams}>
                <p>Additional parameters</p>
                <div className={s.additionalParams__checkboxes}>
                    <div style={{
                        display: 'flex',
                        gap: '6px',
                        alignItems: 'center'
                    }} className={s.form__checkbox}>
                        <Checkbox
                            onChange={(e) => onFormInputChange(i.id, e.target.checked, 'for_expose')}
                            checked={i.for_expose === 1}/>
                        <label className={s.form__label}>Fact
                            sheet</label>
                    </div>
                    <div style={{
                        display: 'flex',
                        gap: '6px',
                        alignItems: 'center'
                    }} className={s.form__checkbox}>
                        <Checkbox
                            onChange={(e) => onFormInputChange(i.id, e.target.checked, 'for_portal')}
                            checked={i.for_portal === 1}/>
                        <label className={s.form__label}>Web
                            Portal</label>
                        <Input value={i.export_order} disabled={i.for_portal === 0} min={1} onChange={(e) => onFormInputChange(i.id, e, 'export_order')} type={'number'} style={{
                            width: '90px',
                            height: '24px',
                            padding: '0 0 0 8px',
                            marginLeft: '5px'
                        }}/>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ShowMore;