import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import PageTitle from "../../common/PageTitle/PageTitle";
import {sessionStorageUtilityValues} from "../../../helpers/sessionStorageHelper";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetBrokeragePropertyByRef,
    GetPropertiesCountries, selectBrokeragePropertyPicturesTabFields,
    selectBrokerageUpdatedPropertyDefaultFields,
    selectBrokerageUpdatedPropertyFields,
    selectIsAllPropertiesAddressModalOpened,
    UpdateBrokerageProperty, UpdateBrokeragePropertyPictures
} from "../../../store/propertiesReducer";
import s from './EditProperty.module.scss'
import {format} from 'date-fns';
import AllPropertiesAddressForm from "../../AllPropertiesAddressForm/AllPropertiesAddressForm";
import {GetPropertiesPictureTypes, GetPropertiesTypes} from "../../../store/utilityReducer";
import SecondaryButton from "../../common/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import {useNavigate} from "react-router";
import EditAddressTab from "./EditAddressTab/EditAddressTab";
import EditPicturesTab from "./EditPicturesTab/EditPicturesTab";

const EditProperty = () => {
    return (
        <PageWithSidebar>
            <EditPropertyComponent/>
        </PageWithSidebar>
    )
}

const EditPropertyComponent = () => {
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty()
    const [currentPageTab, setCurrentPageTab] = useState<'Address' | 'Images'>('Address')
    const dispatch = useAppDispatch()
    const editBrokeragePropertyDefaultFormFields = useAppSelector(selectBrokerageUpdatedPropertyDefaultFields)
    const [fieldsWithError, setFieldsWithError] = React.useState<boolean>(false)
    const formFields = useAppSelector(selectBrokerageUpdatedPropertyFields)
    const formFieldsForPicturesTab = useAppSelector(selectBrokeragePropertyPicturesTabFields)
    const isAddressModalOpened = useAppSelector(selectIsAllPropertiesAddressModalOpened)
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(GetBrokeragePropertyByRef(currentBrokerageProperty.PROP_ID))
    }, [dispatch, currentBrokerageProperty.PROP_ID])

    useEffect(() => {
        dispatch(GetPropertiesCountries())
        dispatch(GetPropertiesTypes())
        dispatch(GetPropertiesPictureTypes())
    }, [dispatch])

    const onUpdateProperty = () => {
        if (isProcessing) return;
        setIsProcessing(true);
        if(currentPageTab === 'Address'){
            if ((formFields.address.cntry_desc_local === null || Number(formFields.address.cntry_desc_local) === 0)
                ||
                (formFields.address.addr_city === null || formFields.address.addr_city === '')
                ||
                (formFields.address.addr_zip === null || formFields.address.addr_zip === '')
                ||
                (formFields.address.addr_street === null || formFields.address.addr_street === '')) {
                setFieldsWithError(true)
                setIsProcessing(false)
            } else {
                dispatch(UpdateBrokerageProperty({ref: currentBrokerageProperty.PROP_ID, reqData: formFields}))
                    .then(() => {
                        setIsProcessing(false)
                    })
            }
        }
        else{
            const formFieldsForUpdate = formFieldsForPicturesTab.filter((i) => i.isEdited === true).map((field, index) => ({
                filename: field.filename || "",
                format: field.format || "",
                title: field.title || "",
                description: field.description || "",
                type: field.type || 0,
                for_expose: field.for_expose || 0,
                for_portal: field.for_portal || 0,
                for_presentation: field.for_presentation || 0,
                is_ref: field.is_ref || 0,
                is_pme: field.is_pme || 0,
                is_locked: field.is_locked || 0,
                index: field.is_index,
                width: field.width || 0,
                height: field.height || 0,
                export_order: field.for_portal === 0 ? null : Number(field.export_order),
                id: field.id
            }));
            formFieldsForUpdate.forEach((i) => {
                dispatch(UpdateBrokeragePropertyPictures({reqData: (({ id, ...rest }) => rest)(i), ref: i.id}))
                    .then(() => {
                        setIsProcessing(false)
                    })
            })

        }


    }

    return (
        <div className={s.inner}>
            <div className={s.inner__content} style={{
                width: currentPageTab === 'Address' ? '83%' : '100%'
            }}>
                <PageTitle
                    isBackButton={true}
                    backLinkSecondText={`Back`}
                    backLink={'/properties'}
                />
                <div>
                    <p className={s.propertyAddress}>{currentBrokerageProperty.PROP_ID} {editBrokeragePropertyDefaultFormFields?.address?.addr_quarter}, {editBrokeragePropertyDefaultFormFields?.address?.addr_street}, {editBrokeragePropertyDefaultFormFields?.address?.addr_zip} {editBrokeragePropertyDefaultFormFields?.address?.addr_city}</p>
                    <div className={s.tabButtons}>
                        <button
                            className={currentPageTab === 'Address' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : `${s.tabButtons__button}`}
                            onClick={() => setCurrentPageTab('Address')}
                            style={{position: "relative"}}
                        >
                            Address
                        </button>
                        <button
                            className={currentPageTab === 'Images' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : `${s.tabButtons__button}`}
                            onClick={() => setCurrentPageTab('Images')}
                            style={{position: "relative"}}
                        >
                            Images
                        </button>
                    </div>
                </div>
                {
                    currentPageTab === 'Address'
                        ?
                        <EditAddressTab fieldsWithError={fieldsWithError}/>
                        :
                        <>
                            <EditPicturesTab/>
                        </>
                }
            </div>
            {
                currentPageTab === 'Address'
                &&
                <div>
                    <p className={s.created}>
                        <span>Created at</span> {format(new Date(currentBrokerageProperty.DATE_CREATED), 'dd.MM.yyyy')} {currentBrokerageProperty.CREATED_BY}
                    </p>
                </div>
            }
            {
                isAddressModalOpened
                &&
                <AllPropertiesAddressForm isPropertyEditMode={true}/>
            }
            <div className={s.buttons} >
                <div onClick={() => navigate('/properties')} onDoubleClick={() => {
                }} style={{
                    width: '240px'
                }}>
                    <SecondaryButton text={'Cancel'} width={'240px'} height={'44px'} isWhiteBg={true}/>
                </div>
                {
                    isProcessing
                        ?
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            gap: '12px'
                        }}
                        >
                            <PrimaryButton disabled={isProcessing} text={'Save'} isAddButton={false} width={'240px'}
                                           height={'44px'} loading={isProcessing}/>
                        </div>
                        :
                        <div style={{
                            width: '240px'
                        }}
                             onClick={onUpdateProperty}
                             onDoubleClick={() => {
                             }}
                        >
                            <PrimaryButton disabled={isProcessing} text={'Save'}
                                           isAddButton={false} width={'240px'}
                                           height={'44px'}/>
                        </div>
                }

            </div>
        </div>

    );
};

export default EditProperty;