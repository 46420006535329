import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectPropertiesPicturesTypes} from "../../../../store/utilityReducer";
import s from "../EditProperty.module.scss";
import {Empty, Input, Select} from "antd";
import {
    GetBrokeragePropertyPicturesByRef,
    onUpdateBrokeragePropertyPictureField, selectBrokeragePropertiesPicturesDataLoading,
    selectBrokeragePropertyPicturesTabFields,
} from "../../../../store/propertiesReducer";
import {sessionStorageUtilityValues} from "../../../../helpers/sessionStorageHelper";
import {format} from "date-fns";
import ShowMore from "./ShowMore/ShowMore";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../../../img/Loader.json";

const EditPicturesTab = () => {
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty()
    const dispatch = useAppDispatch()
    let formFields = useAppSelector(selectBrokeragePropertyPicturesTabFields)
    const picturesTypes = useAppSelector(selectPropertiesPicturesTypes)
    const [propertyPictureType, setPropertyPictureType] = useState('All')
    const isDataLoading = useAppSelector(selectBrokeragePropertiesPicturesDataLoading)

    useEffect(() => {
        if (currentBrokerageProperty.PROP_ID) {
            dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID))
        }
    }, [dispatch, currentBrokerageProperty.PROP_ID])

    const propertiesPicturesTypesValues = [
        {value: "All", label: "All"},
        ...picturesTypes.map((type: { id: number, value: string }) => ({
            value: `${type.id}`,
            label: `${type.value}`,
        })),
    ];

    const onFormInputChange = (id: number, e: React.ChangeEvent<HTMLInputElement> | boolean, inputName: string) => {
        if (typeof e === 'boolean') {
            dispatch(onUpdateBrokeragePropertyPictureField({id, fieldKey: inputName, value: e ? 1 : 0}));
            return;
        } else {
            const {value} = e.target;
            dispatch(onUpdateBrokeragePropertyPictureField({id, fieldKey: inputName, value}));
        }
    };

    const onChangePropertyPictureType = (value: string) => {
        setPropertyPictureType(value)
    }

    if (propertyPictureType === 'All') {
        formFields = formFields.filter((i) => i.type_text !== null)
    } else {
        formFields = formFields.filter((i) => i.type_text === propertyPictureType)
    }

    return (
        <div>
            {
                isDataLoading
                    ?
                    <div style={{
                        height: '52vh',
                        width: '400px',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            height: '300px',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <Lottie
                                // @ts-ignore
                                config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                            />
                        </div>

                        <p style={{
                            textAlign: 'center'
                        }}>Loading...</p>
                    </div>
                    :
                    !formFields.length
                        ?
                        <>
                            <div className={s.form__row} style={{
                                maxWidth: '392px',
                            }}>
                                <label>Picture type</label>
                                <Select
                                    onChange={(e) => onChangePropertyPictureType(e)}
                                    style={{width: '100%'}}
                                    placeholder={'Select type'}
                                    className={'reqCreateFormSelect'}
                                    value={propertyPictureType}
                                >
                                    {
                                        propertiesPicturesTypesValues.map((d: { value: string, label: string }) => {
                                            return (
                                                <option
                                                    key={d.value}
                                                    value={d.label}
                                                >
                                                    {d.label}
                                                </option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '45vh'
                            }}>
                                <Empty/>
                            </div>
                        </>

                        :
                        <>
                            <div className={s.form__row} style={{
                                maxWidth: '392px'
                            }}>
                                <label>Picture type</label>
                                <Select
                                    onChange={(e) => onChangePropertyPictureType(e)}
                                    style={{width: '100%'}}
                                    placeholder={'Select type'}
                                    className={'reqCreateFormSelect'}
                                    value={propertyPictureType}
                                >
                                    {
                                        propertiesPicturesTypesValues.map((d: { value: string, label: string }) => {
                                            return (
                                                <option
                                                    key={d.value}
                                                    value={d.label}
                                                >
                                                    {d.label}
                                                </option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div className={s.picturesInner}>
                                {
                                    formFields.map((i) => {
                                        return (
                                            <div className={s.pictures}>
                                                <div className={s.picture}>
                                                    <div className={s.picture__img}>
                                                        <img src={`data:image/jpeg;base64,${i.raw_data}`} alt="Base64"/>
                                                    </div>
                                                    <div style={{
                                                        width: '100%'
                                                    }}>
                                                        <div className={s.picture__topBlock}>
                                                            <div>
                                                                <p className={s.picture__size}>{i.width} x {i.height}</p>
                                                                <p className={s.picture__id}>Picture number {i.id}</p>
                                                                <p className={s.picture__kind}>Kind: {i.type_text}</p>
                                                            </div>
                                                            <div>
                                                                <p className={s.created} style={{
                                                                    marginBottom: '2px'
                                                                }}>
                                                                    <span>Created at</span> {format(new Date(i.date_created), 'dd.MM.yyyy')} {i.created_by}
                                                                </p>
                                                                <p className={s.created} style={{
                                                                    marginTop: '0px',
                                                                    marginBottom: '0px'
                                                                }}>
                                                                    <span>Updated at</span> {format(new Date(i.date_modified), 'dd.MM.yyyy')} {i.modified_by}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={s.form__row}>
                                                                <label>Title</label>
                                                                <Input placeholder={'Enter title'}
                                                                       value={i.title}
                                                                       onChange={(e) => onFormInputChange(i.id, e, 'title')}/>
                                                            </div>
                                                            <div className={s.form__row} style={{
                                                                marginTop: '6px'
                                                            }}>
                                                                <label>Description</label>
                                                                <Input placeholder={'Enter description'}
                                                                       value={i.description}
                                                                       onChange={(e) => onFormInputChange(i.id, e, 'description')}/>
                                                            </div>
                                                            <ShowMore i={i} onFormInputChange={onFormInputChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>

            }

        </div>
    );
};

export default EditPicturesTab;